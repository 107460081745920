import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { dispatchWindowEvent } from 'helpers/eventDispatchHelper';
import trackEventWithBentoAnalytics from 'helpers/trackEventWithBentoAnalytics';
import { actionTypes, eventLabels } from 'constants/tracking';

const BaseAlertRoot = ({
  templateComponentMap,
  templateCallbackMap,
  getAlert,
  eventType
}) => {
  const [alert, setAlert] = useState();

  const fetchAlert = async () => {
    setAlert(await getAlert());
  };

  useEffect(() => {
    fetchAlert();
  }, []);

  useEffect(() => {
    if (alert && alert.template) {
      templateCallbackMap[alert.template]();
      dispatchWindowEvent(eventType);
      trackEventWithBentoAnalytics(actionTypes.SHOW, eventLabels.ALERT_SHOWN);
    }
  }, [alert]);

  if (alert && alert.template) {
    const AlertContent = templateComponentMap[alert.template];
    return <AlertContent {...alert} />;
  }

  return null;
};

BaseAlertRoot.propTypes = {
  templateComponentMap: PropTypes.shape({
    SENSEI_OSAKA: PropTypes.func.isRequired,
    FUJI: PropTypes.func.isRequired
  }).isRequired,
  templateCallbackMap: PropTypes.shape({
    SENSEI_OSAKA: PropTypes.func.isRequired,
    FUJI: PropTypes.func.isRequired
  }).isRequired,
  getAlert: PropTypes.func.isRequired,
  eventType: PropTypes.string.isRequired
};

export default BaseAlertRoot;
