import SenseiOsakaBannerAlertContent from 'components/senseiOsaka/BannerAlertContent';
import SenseiOsakaOverlayAlertContent from 'components/senseiOsaka/OverlayAlertContent';

import FujiBannerAlertContent from 'components/fuji/BannerAlertContent';
import FujiOverlayAlertContent from 'components/fuji/OverlayAlertContent';

export const OVERLAY_TEMPLATE_COMPONENT_MAP = {
  SENSEI_OSAKA: SenseiOsakaOverlayAlertContent,
  FUJI: FujiOverlayAlertContent
};

export const BANNER_TEMPLATE_COMPONENT_MAP = {
  SENSEI_OSAKA: SenseiOsakaBannerAlertContent,
  FUJI: FujiBannerAlertContent
};
