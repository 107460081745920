import React from 'react';

import BaseAlertRoot from 'containers/BaseAlertRoot';
import { getBannerAlert } from 'api';
import eventTypes from 'constants/eventTypes';
import { BANNER_TEMPLATE_COMPONENT_MAP } from 'templateComponentMap';
import { BANNER_ROOT_INIT_FUNCTION_MAP } from 'rootInitFunctionMap';

const BannerAlertRoot = () => (
  <BaseAlertRoot
    templateComponentMap={BANNER_TEMPLATE_COMPONENT_MAP}
    templateCallbackMap={BANNER_ROOT_INIT_FUNCTION_MAP}
    getAlert={getBannerAlert}
    eventType={eventTypes.SHOW_BANNER}
  />
);

export default BannerAlertRoot;
