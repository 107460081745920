import { BrowserClient, Hub, makeMain } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { SENTRY_DSN, environmentNames } from 'config/constants';
import getEnvironment from 'helpers/getEnvironment';

let _hub;
let _tempHub;

export const _beforeSend = event => {
  event.request = {
    url: window.location.href,
    headers: {
      'User-Agent': window.navigator.userAgent
    }
  };
  return event;
};

export const initializeSentry = () => {
  const { REACT_APP_VERSION } = process.env;

  const ENVIRONMENT_NAME = getEnvironment();

  const client = new BrowserClient({
    // add DEVELOPMENT into the array below if you want sentry logging for local development
    dsn: [environmentNames.PRODUCTION, environmentNames.STAGING].includes(
      ENVIRONMENT_NAME
    )
      ? SENTRY_DSN
      : '',
    debug: false,
    defaultIntegrations: false,
    release: REACT_APP_VERSION,
    environment: ENVIRONMENT_NAME,
    sampleRate: 1.0,
    maxBreadcrumbs: 100,
    normalizeDepth: 3,
    integrations: [new BrowserTracing()],
    beforeSend: _beforeSend
  });

  _hub = new Hub(client);
};

export const logExceptionToSentry = (e, message) => {
  if (!_hub) {
    initializeSentry();
  }
  _hub.captureException(e, {
    captureContext: { extra: { message, error: JSON.stringify(e) } }
  });
};

export const logToSentry = (message, level) => {
  if (!_hub) {
    initializeSentry();
  }
  _hub.captureMessage(message, level);
};

/**
 * Set local Sentry hub to be the main hub before
 * an error is logged via the Sentry ErrorBoundary
 */
export const setMainHub = () => {
  _tempHub = makeMain(_hub);
};

/**
 * Resets main Sentry hub after
 * an error is logged via the Sentry ErrorBoundary
 */
export const resetMainHub = () => {
  makeMain(_tempHub);
  _tempHub = null;
};

/**
 * Primarily used for testing purposes
 */
export const clearMainHub = () => {
  _hub = null;
};
