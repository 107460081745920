/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import React from 'react';
import FujiImage from 'components/FujiImage';

const OverlayAlertContent = ({ content, images, title }) => (
  <div className="modal__viewport">
    <div className="modal__content modal__content--vcenter">
      <div
        className={`modal-article ${
          images.length > 0 ? 'modal-article--has-gallery' : ''
        }`}
      >
        {title && (
          <div className="modal-article__header modal-article__section">
            <h1 className="h2 modal-article__heading">{title}</h1>
          </div>
        )}
        <div
          className="wysiwyg modal-article__section"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        {images.length > 0 && (
          <div
            className="gallery gallery--std"
            data-gallery="std"
            data-gallery-start-index="0"
          >
            {images.map(({ id, image_url, alt_text }, index) => (
              <FujiImage
                key={id}
                image_url={image_url}
                alt_text={alt_text}
                slidePosition={index + 1}
                totalImages={images.length}
              />
            ))}
          </div>
        )}
      </div>
      <div className="modal__actions">
        <button
          className="modal__close-btn"
          type="button"
          data-modal-closer=""
          tabIndex="0"
        >
          <span className="sr-only">Close this modal</span>
          <span className="modal__close-btn__icon" aria-hidden="true">
            &times;
          </span>
        </button>
      </div>
    </div>
  </div>
);

OverlayAlertContent.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      image_url: PropTypes.string.isRequired,
      alt_text: PropTypes.string,
      focal_point_x: PropTypes.number,
      focal_point_y: PropTypes.number
    })
  )
};

OverlayAlertContent.defaultProps = {
  title: '',
  images: []
};

export default OverlayAlertContent;
