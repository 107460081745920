import { get } from 'api/base';
import alertTypes from 'api/constants/alertTypes';
import {
  buildApiPath,
  buildUrlWithHostname,
  getFormattedPathname,
  getHostname,
  getPreviewParam
} from 'api/helpers';
import { logError } from 'helpers/logging';

/**
 * Requests alert from API and resolves with data
 * @param {string} alertType
 * @returns Data promise
 */
const _getAlert = async alertType => {
  const pathname = getFormattedPathname();
  const previewParam = getPreviewParam();
  const apiPath = buildApiPath(alertType, pathname, previewParam);
  const hostname = getHostname();
  const apiEndpointUrl = buildUrlWithHostname(hostname, apiPath);

  try {
    const response = await get(apiEndpointUrl);
    return response.data;
  } catch (error) {
    logError(
      `Error requesting the active ${alertType} alert. Status: ${error?.response?.status}`,
      error
    );
    return null;
  }
};

/**
 * Requests Overlay Alert from API
 * @returns Data promise
 */
export const getOverlayAlert = () => _getAlert(alertTypes.OVERLAY);

/**
 * Requests Banner Alert from API
 * @returns Data promise
 */
export const getBannerAlert = () => _getAlert(alertTypes.BANNER);
